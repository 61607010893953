import React from "react";
import styles from "./ProductDialog.module.css"; // Import CSS module

function ProductDialog({ product, onClose }) {
  console.log(product);
  return (
    <div className={styles.productDialogOverlay}>
      <div className={styles.productDialog}>
        <button className={styles.closeButton} onClick={onClose}>
          X
        </button>
        <div className={styles.dialogContent}>
          <div className={styles.dialogImageContainer}>
            <img
              src={product.image}
              alt={product.name}
              className={styles.dialogImage}
            />
          </div>
          <div className={styles.dialogInfo}>
            <h2 className={styles.dialogTitle}>{product.name}</h2>
            <p className={styles.dialogDescription}>{product.description}</p>
            <div className={styles.dialogFooter}>
              <span className={styles.dialogPrice}>${product.price}</span>
              <a href={product.link} className={styles.buyButton}>
                Buy now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDialog;
import React, { useState } from "react";
import ProductDialog from "./components/ProductDialog"; // Import the new ProductDialog component
import styles from "./ShopPage.module.css"; // Assuming ShopPage.module.css contains the necessary styles

const products = [
  {
    name: "Mejora tus finanzas",
    link: "https://buy.stripe.com/test_3cs8ze6Ai9ym13ybII",
    image: "products-image/selling.png",
    price: 0.99,
    description:
      "Improve your financial habits and take control of your finances with our expert tools.",
  },
  {
    name: "Organiza los horarios de tu universidad",
    link: "/universidad",
    image: "products-image/selling.png",
    description:
      "Manage your university schedule with our customizable planner.",
  },
  {
    name: "Escoge los horarios de tu chamba",
    link: "/chamba",
    image: "products-image/selling.png",
    description:
      "Plan and manage your work tasks effectively with our project management tools.",
  },
];

const ShopPage = () => {
  const selectedProduct = products[0];
  // const [selectedProduct, setSelectedProduct] = useState(products[0]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCardClick = (product) => {
    // setSelectedProduct(product[0]);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className={styles.shopPageContainer}>
      {/* Left Box - Title and Buttons */}
      <div className={styles.titleBox}>
        <h1 className={styles.shopHeading}>Select our products</h1>
        <p className={styles.shopSubheading}>
          Click on our cards to know more information
        </p>
      </div>

      {/* Right Box - Card Display using CardDisplayed */}
      <div
        className={styles.cardBox}
        onClick={handleCardClick}
        style={{ cursor: "pointer" }}
      >
        <div className={styles.card}>
          <div className={styles.cardImageWrapper}>
            <img
              src={selectedProduct.image}
              alt={selectedProduct.name}
              className={styles.cardImage}
            />
          </div>
          <div className={styles.cardContent}>
            <h3>{selectedProduct.name}</h3>
            <p>{selectedProduct.description}</p>
          </div>
        </div>
      </div>

      {/* Right Box - Card Display */}
      {isDialogOpen && (
        <ProductDialog
          product={products[0]}
          onClose={handleCloseDialog}
        />
      )}
    </div>
  );
};

export default ShopPage;

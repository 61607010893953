import React from "react";
import styles from "./Ribbon.module.css";
import { Link } from "react-router-dom";

const Ribbon = () => {
  return (
    <header className={styles.ribbon}>
      {/* Left logo */}
      <div className={styles.logoContainer}>
        <Link to="/">
          <img src="logo.png" alt="Logo" className={styles.logo} />
        </Link>
      </div>

      {/* Center buttons */}
      <div className={styles.centerButtons}>
        <Link to="/shop" className={styles.navButton}>
          Shop
        </Link>
        <Link to="/learn" className={styles.navButton}>
          Learn
        </Link>
      </div>
    </header>
  );
};

export default Ribbon;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Ribbon from "./pages/components/Ribbon";
import MainPage from "./pages/MainPage";
import ShopPage from "./pages/ShopPage";
import LearnPage from "./pages/LearnPage";
import { register } from "swiper/element/bundle";

function App() {
  register();

  return (
    <div className="app-layout">
      <Router>
        <Ribbon />
        <Routes>
          <Route
            path="/"
            element={
              <div class="main-container">
                <MainPage />
              </div>
            }
          />
          <Route
            path="/shop"
            element={
              <div class="main-container">
                <ShopPage />
              </div>
            }
          />
          <Route
            path="/learn"
            element={
              <div class="main-container">
                <LearnPage />
              </div>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React from "react";
import styles from "./MainPage.module.css";

function MainPage() {
  return (
    <div className={styles.mainBoxPage}>
      <h1 className={styles.mainHeading}>Invest in your life</h1>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <img src="hands.png" alt="Card 1" className={styles.cardImage} />
          <div className={styles.cardContent}>
            <h3>Need to organize your finance?</h3>
            <p>
              Our tools will help you create solid habits where you will learn
              to structure your days, stay focused on your goals and be
              consistent in your progress. <a href="#about">about us</a>
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <img src="people.png" alt="Card 2" className={styles.cardImage} />
          <div className={styles.cardContent}>
            <h3>Focus on your work</h3>
            <p>
              Our tools will help you create solid habits where you will learn
              to structure your days, stay focused on your goals and be
              consistent in your progress. <a href="#about">about us</a>
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <img src="abstract.png" alt="Card 3" className={styles.cardImage} />
          <div className={styles.cardContent}>
            <h3>Need to organize your finance?</h3>
            <p>
              Our tools will help you create solid habits where you will learn
              to structure your days, stay focused on your goals and be
              consistent in your progress. <a href="#about">about us</a>
            </p>
          </div>
        </div>
      </div>

      {/* Specific Footer Text */}
      <div className={styles.footerText}>
        Our tools will help you create solid habits where you will learn to
        structure your days, stay focused on your goals and be consistent in
        your progress. <a href="#about">about us</a>
      </div>
    </div>
  );
}

export default MainPage;

import React from "react";
import styles from "./LearnPage.module.css"; // Import CSS module

const cards = [
  {
    title: "Invest in your life",
    description:
      "Our tools will help you create solid habits where you will learn to structure your days, stay focused on your goals and be consistent in your progress.",
    imageUrl: "people.png", // Replace with your correct image path
    linkText: "Ver mas...",
    linkUrl: "",
  }
];

function CardLearn({ title, description, imageUrl, linkText, linkUrl }) {
  return (
    <div className={styles.cardLearn}>
      <div
        className={styles.cardLearnImage}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      ></div>
      <div className={styles.cardLearnContent}>
        <h2>{title}</h2>
        <p>{description}</p>
        <a href={linkUrl} className={styles.cardLearnLink}>
          {linkText}
        </a>
      </div>
    </div>
  );
}

function LearnPage() {
  return (
    <div className={styles.learnPageContainer}>
      <h1 className={styles.learnPageTitle}>
        Tips on how to design your own life
      </h1>
      <div className={styles.learnCardsContainer}>
        {cards.map((card, index) => (
          <CardLearn
            key={index}
            title={card.title}
            description={card.description}
            imageUrl={card.imageUrl}
            linkText={card.linkText}
            linkUrl={card.linkUrl}
          />
        ))}
      </div>
    </div>
  );
}

export default LearnPage;
